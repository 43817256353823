.App {
  background-color: rgb(3, 3, 66);
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

  height: 100vh;
  width: 100vw;
}

body{
  padding: 0;
  margin: 0;
}

.ast {
  background: linear-gradient(90deg, #161122 21px, transparent 1%) center, linear-gradient(#161122 21px, transparent 1%) center, #a799cc;
  background-size: 22px 22px;
}